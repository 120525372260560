.navbar-brand {
	line-height: 1;
}

.navbar-brand img {
	height: 2em;
}

.sticky-thead td, .sticky-thead th {
	position: sticky;
	top: 0;
	background-color: #fff;
}
