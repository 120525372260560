.omg-btn {
	color: #fff;
	background-color: #636363;
	transition: background .2s, color .2s;
	cursor: pointer;
	border-color: transparent;
}

[type="submit"].omg-btn {
	font-weight: bold;
}

.omg-btn:active, .omg-btn:focus {
	color: white;
}

.omg-btn:hover {
	background-color: #636363 !important;
	color: #fff !important;
}

.omg-btn.blue {
	background-color: #00aeef;
}

.omg-btn.purple {
	background-color: #9c3f98;
}

.omg-btn.red {
	background-color: #ef4135;
}

.omg-btn.white {
	background-color: #fff;
	color: #333;
}

.multi-select {
	min-width: 200px;
}

table td {
	font-size: 10pt;
}

.table tr:first-of-type td {
	border-top: none;
}

.table thead th {
	border-top: none;
}

.table {
	border-top: 1px solid #dee2e6;
	border-collapse: separate;
}
